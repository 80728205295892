import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"460"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.titleText))]),_c(VCardText,[_vm._v(_vm._s(_vm.messageText))]),_c(VCardActions,[_c(VSpacer),(_vm.btCloseVisible)?_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.close(10)}}},[_vm._v(_vm._s(_vm.btCloseText))]):_vm._e(),(_vm.btYesVisible)?_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.close(20)}}},[_vm._v(_vm._s(_vm.btYesText))]):_vm._e(),(_vm.btNoVisible)?_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.close(30)}}},[_vm._v(_vm._s(_vm.btNoText))]):_vm._e(),(_vm.btCancelVisible)?_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.close(40)}}},[_vm._v(_vm._s(_vm.btCancelText))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }