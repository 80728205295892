import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/mainmenu',
    name: 'MainMenu',
    component: () => import('../views/MainMenu.vue')
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: () => import('../views/zDashBoard.vue')
  },
  {
    path: '/workcalendarlist',
    name: 'WorkCalendarList',
    component: () => import('../views/WorkCalendarList.vue')
  },
  {
    path: '/workcalendaredit',
    name: 'WorkCalendarEdit',
    component: () => import('../views/WorkCalendarEdit.vue')
  },
  {
    path: '/workdaysummary',
    name: 'WorkDaySummary',
    component: () => import('../views/WorkDaySummary.vue')
  },
  {
    path: '/workorder01edittime',
    name: 'WorkOrder01EditTime',
    component: () => import('../views/WorkOrder01EditTime.vue')
  },
  {
    path: '/workorder02edit',
    name: 'WorkOrder02Edit',
    component: () => import('../views/WorkOrder02Edit.vue')
  },
  {
    path: '/workorder02list',
    name: 'WorkOrder02List',
    component: () => import('../views/WorkOrder02List.vue')
  },
  {
    path: '/workorder02listsummary',
    name: 'WorkOrder02ListSummary',
    component: () => import('../views/WorkOrder02ListSummary.vue')
  },
  {
    path: '/workorderhistory',
    name: 'WorkOrderHistory',
    component: () => import('../views/WorkOrderHistory.vue')
  },
  {
    path: '/workorderstate',
    name: 'WorkOrderState',
    component: () => import('../views/WorkOrderState.vue')
  },
  {
    path: '/workperformed',
    name: 'WorkPerformed',
    component: () => import('../views/WorkPerformed.vue')
  },
  {
    path: '/listitems00list',
    name: 'ListItems00List',
    component: () => import('../views/ListItems00List.vue')
  },
  {
    path: '/listitems00edit',
    name: 'ListItems00Edit',
    component: () => import('../views/ListItems00Edit.vue')
  },
  {
    path: '/listitems01list',
    name: 'ListItems01List',
    component: () => import('../views/ListItems01List.vue')
  },
  {
    path: '/listitems01edit',
    name: 'ListItems01Edit',
    component: () => import('../views/ListItems01Edit.vue')
  },
  {
    path: '/logisticupdates',
    name: 'LogisticUpdates',
    component: () => import('../views/LogisticUpdates.vue')
  },
  {
    path: '/worktimeedit',
    name: 'WorkTimeEdit',
    component: () => import('../views/WorkTimeEdit.vue')
  },
  {
    path: '/timecontrol',
    name: 'TimeControl',
    component: () => import('../views/TimeControl.vue')
  },
  {
    path: '/googlemaps',
    name: 'GoogleMaps',
    component: () => import('../views/zGoogleMaps.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router