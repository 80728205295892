//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["params"],
  data() {
    return {
      hores: 0,
      minuts: 0,
      editDialog: false,
      editableTime: null,
      minutsRules: [
        //(v) => !!v || "This field is required",
        (v) => (v && v >= 0) || "Minutes should be 0-59 1",
        (v) => (v && v <=59) || "Minutes should be 0-59 2",
      ],
    };
  },
  methods: {
    onSaveChanges() {
      //const newDate = new Date(this.params.date);
      //const hours = this.editableTime.match(/^(\d+)/)[1];
      //const minutes = this.editableTime.match(/:(\d+)/)[1];
      //newDate.setHours(hours);
      //newDate.setMinutes(minutes);

      //this.$store.dispatch("updateMeetupData", {
      //  id: this.params.id,
      //  date: newDate,
      //});
      this.editDialog = false;
    },
    created() {
      this.editableTime = new Date(this.params.date).toTimeString();
    },

    horesAdd(q) {
      if (q > 0) {
        if (this.hores + parseInt(q) > 23) {
          this.hores = 23;
        } else {
          this.hores = parseInt(this.hores) + parseInt(q);
        }
      }
      if (q < 0) {
        if (this.hores + parseInt(q) < 0) {
          this.hores = parseInt(0);
        } else {
          this.hores = parseInt(this.hores) + parseInt(q);
        }
      }
    },

    minutsAdd(q) {
      if (q > 0) {
        if (this.minuts + parseInt(q) > 59) {
          this.minuts = 59;
        } else {
          this.minuts = parseInt(this.minuts) + parseInt(q);
        }
      }
      if (q < 0) {
        if (this.minuts + parseInt(q) < 0) {
          this.minuts = parseInt(0);
        } else {
          this.minuts = parseInt(this.minuts) + parseInt(q);
        }
      }
    },

    qtyAdd(q) {
      this.itemOne.qty = parseFloat(this.itemOne.qty) + parseInt(q);
    },
  },
};
