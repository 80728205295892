//
//
//
//
//
//
//
//
//

//1   import axios from "axios";
//1   import api from "./api.js";
//2   import cookies from "vue-cookies";
//2   import {version} from '../package'
//3   import cookies from "vue-cookies";

export default {
  name: "App",
  /*1
  data: () => ({
    version: "1.0.0_20220425_0900",
  }),
  created() {
    axios.get(api.URL() + "/api/v1/getwebversion").then((response) => {
      if (this.version != response.data) window.location.reload(true);
    });
  },
1*/
  /*2
  data: () => ({
    appVersion: version,
    cacheVersion: cookies.get("natter_AppVersion"),
      }),
  mounted() {
      if (this.appVersion != this.cacheVersion) {
        cookies.set("natter_AppVersion", this.appVersion, "365d");
        window.location.reload(true);
      }
  },
2*/
  /*3
  data: () => ({}),
  mounted() {
    this.$root.$on("component1", () => {
      window.location.reload(true);
      //this.c1method();
    });
  },
  methods: {
    naForceUpdate: function (appNewVersion) {
      cookies.set("ACVUpdate", appNewVersion, Infinity);
      this.$forceUpdate();
      window.location.reload();
    },
  },
  3*/
};

