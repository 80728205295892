//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Alert from "./alert.js";

export default {
  data() {
    return {
      dialog: false,
      bMode: "",
      titleText: "Attention",
      messageText: "",
      btCloseText: "Close",
      btYesText: "Si",
      btNoText: "No",
      btCancelText: "Cancel",
      btCloseVisible: false,
      btYesVisible: false,
      btNoVisible: false,
      btCancelVisible: false,
      onClose: {},
    };
  },
  methods: {
    show(params) {
      this.dialog = true;
      if (params.mode && params.mode.trim() != "") {
        this.bMode = params.mode.toLowerCase();
      }
      if (params.title && params.title.trim() != "") {
        this.titleText = params.title;
      }
      this.messageText = params.message;
      this.onClose = params.onClose;
      this.btYesVisible = this.bMode.includes("#yes#");
      this.btNoVisible = this.bMode.includes("#no#");
      this.btCancelVisible = this.bMode.includes("#cancel#");
      this.btCloseVisible = true; //this.bMode.includes('#close#') || (!this.btYesVisible && !this.btNoVisible && !this.btCancelVisible);
    },
    close() {
      this.dialog = false;
      this.onClose;
    },
  },
  beforeMount() {
    Alert.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
