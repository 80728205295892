import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import Alert from './plugins/alert'
import EditDateDialog from './components/Editors/EditDateDialog.vue'
import EditTimeDialog from './components/Editors/EditTimeDialog.vue'

//import x5GMaps from 'x5-gmaps'
//Vue.use(x5GMaps, { key: 'AIzaSyDryosbfJMjBXQPeX70ZS9Zq_UG5Oh_CMc', libraries: ['places'] })

Vue.config.productionTip = false
Vue.store = Vue.prototype.isShowed = false;
Vue.store = Vue.prototype.isUpdated = false;
Vue.use(Alert);
Vue.component('app-edit-date-dialog', EditDateDialog)
Vue.component('app-edit-time-dialog', EditTimeDialog)

/*
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
*/

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    Vue.prototype.$config = config
    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })